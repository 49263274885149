import constant from '@/constant/constant'

export function getSpkApprovalType (jenisSpk) {
  if (jenisSpk === constant.JENIS_SPK.UNIT) {
    return 'SPP-KONTRAK-UNIT'
  } else if (jenisSpk === constant.JENIS_SPK.NON_UNIT) {
    return 'SPP-KONTRAK-NONUNIT'
  } else if (jenisSpk === constant.JENIS_SPK.PERENCANAAN) {
    return 'SPP-KONTRAK-PERENCANAAN'
  } else if (jenisSpk === constant.JENIS_SPK.MARKETING) {
    return 'SPP-KONTRAK-MARKETING'
  } else if (jenisSpk === constant.JENIS_SPK.LEGAL) {
    return 'SPP-KONTRAK-LEGAL'
  } else if (jenisSpk === constant.JENIS_SPK.ESTATE) {
    return 'SPP-KONTRAK-ESTATE'
  } else if (jenisSpk === constant.JENIS_SPK.HRGA) {
    return 'SPP-KONTRAK-HRGA'
  } else if (jenisSpk === constant.JENIS_SPK.OTHER) {
    return 'SPP-KONTRAK-OTHER'
  } else {
    return null
  }
}
