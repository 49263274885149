<template>
  <vs-popup title="Approval Progress" :active="isActive" v-on:update:active="emitModalIsActive">
    <div class="w-full">
      <ApprovalProgress ref="approvalProgress" v-if="isActive" :type="type" :id-ref="idRef"/>
    </div>

    <div v-if="$can('undo_approve')">
      <vs-divider></vs-divider>
      <vs-button class="" size="small" @click="modalUndoApprove.active = true">Undo Approve</vs-button>
    </div>

    <UndoApprove :isActive.sync="modalUndoApprove.active"
                 :approvalType="type"
                 :idTrxRef="idRef"
                 @success="$refs.approvalProgress.refresh()"/>
  </vs-popup>
</template>

<script>
import ApprovalProgress from '@/views/components/approval-progress/ApprovalProgress'
import UndoApprove from '@/views/pages/approvals/UndoApprove.vue'

export default {
  name: 'ModalApprovalProgress',
  props: {
    isActive: { required: true },
    type: { required: true },
    idRef: { required: true }
  },
  components: {
    UndoApprove,
    ApprovalProgress
  },
  data () {
    return {
      modalUndoApprove: {
        active: false,
        approvalType: this.type,
        isTrxRef: this.idRef
      }
    }
  },
  methods: {
    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
