<template>
  <ul class="vx-timeline">
    <li v-for="(item, index) in items" :key="index">
      <div class="timeline-icon" :class="`bg-${item.color || 'primary'}`">
        <feather-icon :icon="(item.icon || 'StarIcon')" svgClasses="text-white stroke-current w-5 h-5"/>
      </div>
      <div class="timeline-info">
        <p class="font-semibold text-sm">{{ item.title || '-' }}</p>
        <span v-if="item.desc" class="activity-desc opacity-75">{{ item.desc }}</span>
      </div>
      <small v-if="item.subDesc" class="opacity-50 activity-e-time">{{ item.subDesc }}</small>
      <template v-if="item.fileSignatureUrl">
        <p class="text-xs underline cursor-pointer select-none opacity-75" @click="item.isShowSignature = !item.isShowSignature">{{ item.isShowSignature ? 'Hide Signature' : 'Show Signature' }}</p>
        <div class="w-32 h-32 bg-white mt-2" v-if="item.isShowSignature">
          <img :src="item.fileSignatureUrl" alt="Signature" class="mx-auto max-w-full">
        </div>
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ApprovalTimeline',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) {
          this.items = val
        }
      }
    }
  },
  data () {
    return {
      items: []
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/vuexy/components/vxTimeline.scss';
</style>
