<template>
  <div class="w-full h-full relative z-0">
    <div v-if="isLoading" class="absolute inset-0 flex justify-center items-center">
      <feather-icon icon="LoaderIcon" svgClasses="h-8 w-8" class="animate-spin-slow"/>
    </div>
    <template v-else>
      <div v-if="listApprovalProgress.length > 0">
        <ApprovalTimeline :data="listApprovalProgress"></ApprovalTimeline>
        <div class="mt-base" v-if="isShowSigningAction && isEligibleToSign">
          <vs-button class="w-full mb-1" color="danger" icon-pack="feather" icon="icon-x" @click="emitSigningClicked('DITOLAK')">REJECT</vs-button>
          <vs-button class="w-full mb-1" color="warning" icon-pack="feather" icon="icon-edit" @click="emitSigningClicked('DIREVISI')">REVISI</vs-button>
          <vs-button class="w-full mb-1" color="success" icon-pack="feather" icon="icon-check" @click="emitSigningClicked('DITERIMA')">APPROVE</vs-button>
        </div>
      </div>
      <span v-else class="text-sm">Tidak ada data</span>
    </template>
  </div>
</template>

<script>
import ApprovalRepository from '@/repositories/general/approval-repository'
import ApprovalTimeline from '@/views/components/approval-progress/ApprovalTimeline'
import _ from 'lodash'

export default {
  name: 'ApprovalProgress',
  props: {
    type: { required: true, type: String },
    idRef: { required: true, type: [Number, String] },
    isShowSigningAction: { default: false, type: Boolean }
  },
  components: {
    ApprovalTimeline
  },
  mounted () {
    this.refresh()
  },
  computed: {
    listApprovalProgress () {
      return this.generateListApprovals(this.approvals)
    },
    isEligibleToSign () {
      const approvals = _.orderBy(this.approvals, ['step'], ['asc'])
      let isCurrentUserOrRoleEligibleToSign = false
      const finalStepApprovals = approvals.length

      if (finalStepApprovals > 0) {
        const isApprovalCanContinueToNextStep = _.find(approvals, approval => (approval.status === 'DITOLAK') || (approval.status === 'DIREVISI')) === undefined
        if (isApprovalCanContinueToNextStep) {
          const currentStepApproval = _.find(approvals, approval => !approval.signed_at) // first approval that not signed
          if (currentStepApproval !== undefined) {
            if (this.$can('view_all_approvals')) {
              isCurrentUserOrRoleEligibleToSign = true
            } else if (currentStepApproval.id_user) {
              const currentUserId = this.$store.state.auth.user.id
              isCurrentUserOrRoleEligibleToSign = currentStepApproval.id_user.toString() === currentUserId.toString()
            } else {
              const currentUserRole = this.$store.state.auth.user.role.id
              isCurrentUserOrRoleEligibleToSign = currentStepApproval.id_role.toString() === currentUserRole.toString()
            }
          }
        }
      }

      return isCurrentUserOrRoleEligibleToSign
    }
  },
  data () {
    return {
      isLoading: false,
      approvals: []
    }
  },
  methods: {
    refresh () {
      this.approvals = []
      this.getInitData()
    },

    getInitData () {
      this.isLoading = true

      const params = { type: this.type, id_ref: this.idRef }
      ApprovalRepository.get(params)
        .then(response => {
          this.approvals = response.data.data
          this.emitApprovalsData(this.approvals)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    emitApprovalsData (data) {
      this.$emit('update:approvalsData', data)
    },

    emitSigningClicked (status) {
      this.$emit('signingClicked', status)
    },

    generateListApprovals (approvals) {
      const vue = this
      const generateItem = function (groupItems) {
        const title = _.map(groupItems, item => {
          const isForSpecificUser = !!item.id_user
          return isForSpecificUser ? item.user_name : item.role_name
        }).join(' | ')
        const itemAlreadySigned = _.find(groupItems, item => item.signed_by)
        const isAlreadySigned = !!itemAlreadySigned
        const color = isAlreadySigned ? vue.getStatusColor(itemAlreadySigned.status) : vue.getStatusColor(null)
        const icon = isAlreadySigned ? vue.getStatusIcon(itemAlreadySigned.status) : vue.getStatusIcon(null)
        const desc = isAlreadySigned ? itemAlreadySigned.description : null
        const subDesc = isAlreadySigned ? `${itemAlreadySigned.status} oleh ${itemAlreadySigned.signed_by_username} - ${itemAlreadySigned.signed_at}` : 'Belum ada tanggapan'
        const fileSignatureUrl = isAlreadySigned ? itemAlreadySigned.file_signature_url : null
        return {
          title,
          color,
          icon,
          desc,
          subDesc,
          fileSignatureUrl,
          isAlreadySigned,
          isShowSignature: false
        }
      }

      const approvalGroupedByStep = _.groupBy(approvals, item => item.step)
      const listApprovals = []
      _.each(approvalGroupedByStep, groupItems => {
        const itemProgress = generateItem(groupItems)
        listApprovals.push(itemProgress)
      })

      return listApprovals
    },

    getStatusColor (status) {
      switch (status) {
        case 'DIREVISI':
          return 'orange'
        case 'DITOLAK':
          return 'danger'
        case 'DITERIMA':
          return 'success'
        default:
          return 'grey'
      }
    },

    getStatusIcon (status) {
      switch (status) {
        case 'DIREVISI':
          return 'EditIcon'
        case 'DITOLAK':
          return 'XIcon'
        case 'DITERIMA':
          return 'CheckIcon'
        default:
          return 'ClockIcon'
      }
    }
  }
}
</script>
