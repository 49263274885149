import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/spk'

export default {
  getAllByProyek (idProyek) {
    return httpClient.get(`${endpoint}/allByProyek/${idProyek}`)
  },

  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  getSpkPekerjaan (idSpk, params = null) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/${idSpk}/pekerjaan${queryParams}`)
  },

  getSpkTermin (idSpk, params = null) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/${idSpk}/termin${queryParams}`)
  },

  getSpkFiles (idSpk, params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/${idSpk}/files${queryParams}`)
  },

  getSpkProgress (idSpk, params = null) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/${idSpk}/progress${queryParams}`)
  },

  show (idSpk) {
    return httpClient.get(`${endpoint}/${idSpk}`)
  },

  showPekerjaan (idSpkPekerjaan, params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/pekerjaan/${idSpkPekerjaan}${queryParams}`)
  },

  showTermin (idSpkD, params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/termin/${idSpkD}${queryParams}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.post(`${endpoint}/update`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  }
}
