<template>
  <div>
    <vs-popup class="sm:popup-w-30" title="Undo Approve" :active="isActive" v-on:update:active="emitModalIsActive">

      <div :class="{ 'opacity-50 pointer-events-none': isLoadingInitData }">
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Undo To *</label>
            <vs-select v-model="data.to_id_approval" class="w-full">
              <vs-select-item v-for="(it, index) in listApprovalLevel" :key="index" :value="it.id_approval" :text="`${it.step}. ${it.person_name || it.role_name}`"/>
            </vs-select>
          </div>
        </div>
<!--        <div class="vx-row mb-3">-->
<!--          <div class="vx-col sm:w-1/1 w-full">-->
<!--            <label class="ml-1 text-xs">Description</label>-->
<!--            <vs-textarea class="w-full" v-model="data.keterangan"/>-->
<!--          </div>-->
<!--        </div>-->
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Cancel</vs-button>
            <vs-button :disabled="isSaving || listApprovalLevel.length < 1" @click="save">
              <span v-if="isSaving" class="animate-pulse">Saving...</span>
              <span v-if="!isSaving">Process Undo</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import UndoApproveRepository from '@/repositories/approvals/undo-approve-repository'
import _ from 'lodash'

export default {
  name: 'UndoApprove',
  props: ['isActive', 'approvalType', 'idTrxRef'],
  components: {
    ValidationErrors
  },
  data () {
    return {
      isLoadingInitData: false,
      isSaving: false,
      errors: null,
      data: {
        to_id_approval: null,
        description: null
      },
      listApprovalLevel: []
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getListApprovalLevel()
      } else {
        this.resetData()
      }
    }
  },
  methods: {
    getListApprovalLevel () {
      this.isLoadingInitData = true

      const params = {
        id_ref: this.idTrxRef,
        type: this.approvalType
      }
      UndoApproveRepository.getApprovers(params)
        .then(response => {
          this.listApprovalLevel = response.data.data
          if (this.listApprovalLevel.length < 1) {
            this.errors = ['Tidak dapat melakukan undo approve']
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    save () {
      this.errors = null
      this.isSaving = true

      const params = this.buildParams()
      UndoApproveRepository.undo(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    buildParams () {
      return {
        to_id_approval: this.data.to_id_approval,
        description: this.data.description
      }
    },

    onSuccess () {
      this.notifySuccess('Undo approve success')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.$store.dispatch('general/navbarBadge/getDataForMenuApproval')
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
