import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/undoApprove'

export default {
  getApprovers (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/approvers${queryParams}`)
  },

  undo (params) {
    return httpClient.post(`${endpoint}/undo`, params)
  }
}
